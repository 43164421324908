import PropTypes from "prop-types"
import React from "react"

import "../sass/components/_youtube.scss"

const Youtube = ({ url, autoplay }) => (
  <div className="youtubeContainer">
    <iframe
      title="youtube-opera-2-aftermovie"
      width="560"
      height="315"
      src={url}
      frameborder="0"
      allow={`${
        autoplay ? "autoplay;" : ""
      } encrypted-media; picture-in-picture;`}
      allowfullscreen
    ></iframe>
  </div>
)

Youtube.propTypes = {
  url: PropTypes.string,
}

Youtube.defaultProps = {
  url: ``,
}

export default Youtube
