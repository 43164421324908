import React from "react"
import styled from 'styled-components'
import { device, sizes } from '../config'

import Logo from "../components/images/logo"
import SEO from "../components/seo"
import Youtube from "../components/youtube"

import "../sass/pages/_index.scss"

const StyledHeading = styled.h1`
  letter-spacing: ${sizes[0]};
  text-align: center;

  @media ${device.tablet} {
      letter-spacing: ${sizes[1]}px;
  }
`

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <Youtube
      autoplay
      url="https://www.youtube.com/embed/8YUxBb2qPQA?autoplay=1&mute=1&loop=1&modestbranding=1&rel=0&playlist=8YUxBb2qPQA"
    />
    <StyledHeading className="heading">\\AUDIOVISUAL COLLECTIVE//</StyledHeading>
    <div className="flex-container">
      <div className="flex-item left">
        <div className="content">
          <div className="content-inner">
            <h2>The Orchestra, Eindhoven</h2>
            <p>
              A non-profit organisation of internationals with creative and
              technical backgrounds.
            </p>
            <p>
              Our name derives from our aim to Orchestrate unique social
              &amp; audio/visual experiences in Eindhoven.
            </p>
            <p>
              In 2019 we Orchestrated <b>The Opera</b>, <b>Operetta</b> and{" "}
              <b>Soundscape Weaving</b>.
            </p>
            <p>
              In 2020 we created <b>Virtual Operetta</b>, went <b>underground</b> and ....TBA
            </p>
          </div>
        </div>
      </div>
      <div className="flex-item">
        <div className="content">
          <Logo />
        </div>
      </div>
    </div>
  </>
)

export default IndexPage
