import React from "react"
import styled from "styled-components"

const  StyledSvg = styled.svg`
.st0{stroke:#FFFFFF;stroke-width:0.5;stroke-linecap:round;stroke-linejoin:round;}
.st1{stroke:#FFFFFF;stroke-width:0.8;stroke-miterlimit:10;}
.st2{fill:#FFFFFF;}
.st3{fill:#FFFFFF;stroke:#000000;stroke-width:0.8;stroke-miterlimit:10;}
`

const Logo = () => {
  return (
  <StyledSvg version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
     viewBox="0 0 1190.6 833.2">
    <g id="Livello_1">
      <g>
        <path class="st2" d="M1007.7,411.9c-43.7-73.7-151.6-140.6-271.6-184.3c-249.3-90.9-550.6-81.6-434.5,175.9
          c0,0-13,61.9,18.8,105.2c-14.8,28.2-41.5,65-36.4,119.3l2.9-0.3c1.3-38.3,42.2-108.2,51.6-123.9c68.1-38.9,58.2-196.6-14.5-136.8
          c-1.4,1.1-2.9,2.4-4.4,3.9c-1.4,1.3-2.9,2.6-4.4,4.1c0,0-84.5-149.5,84.5-179.8c130-16.9,243.9,0.5,336,33.7
          c184.5,66.4,282,196,248.3,239.3c-94.6,105.5-224.6,164.6-360.3,157.4c0,0-104.9-5.8-208.3-67c-5.6-3.2-13.3-6.3-21.5-7.7l0.1-0.7
          c7.9-17.3,36.2-95.1,27.5-140.5c-15.2-62.8-14.4-72.2-21.7-119.1c0,49.1,15.2,101.1,14.5,136.5c-0.7,35.4-58.8,138.6-58.8,138.6
          l0.1,0.1c-12,17.9-22.4,49.6-46.1,54.1c30.3-5.8,41.9-32.5,59.9-49.8c5.3-5.1,11.7-6.4,18.2-5.7c15.5,1.7,31,15.1,31,15.1
          c270,161.7,469.3-7.2,541.5-62.1C1032.3,462.4,1014.9,423.4,1007.7,411.9z"/>
        <path class="st2" d="M612.5,467.2c239-53.5-67.5-220-137.3-99.3C475.2,367.9,487.9,489.8,612.5,467.2z"/>
      </g>
    </g>
  </StyledSvg>
  )
  
}

export default Logo
